// - VARIABLES
$sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$small: 18px;
$medium: 22px;
$large: 25px;
$grey: #333;

// - STYLES
body {
  font-family: $sans;
  color: #333;
  line-height: 25px;
}

p, li {
  line-height: 35px;
  font-size: $small;
}

.container {
  width: 90%;
  max-width: 600px;
  padding-top: 50px;
  margin: auto;
}

// IMAGES
.img-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

//

i {
  padding-right: 10px;
}

a {
  text-decoration: none;
  color: orange;
}

h1:first-of-type {
  color: $grey;
}

footer {
  margin-bottom: 80px;
}

.linkBox {
  margin-bottom: 10px;
}
